/*Pizza Images*/
import PizzaChevreMiel from "../assets/img/carte/pizza/Chèvre miel.png";
import PizzaCheese from "../assets/img/carte/pizza/Cheese.png";
import PizzaCampione from "../assets/img/carte/pizza/Campione.png";
import PizzaBoursin from "../assets/img/carte/pizza/Boursin.png";
import Pizza4Fromages from "../assets/img/carte/pizza/4 fromages.png";
import PizzaVegetarien from "../assets/img/carte/pizza/Végétarien.png";
import PizzaThon from "../assets/img/carte/pizza/Thon.png";
import PizzaTartiflette from "../assets/img/carte/pizza/Tartiflette.png";
import PizzaSaumon from "../assets/img/carte/pizza/Saumon.png";
import PizzaReine from "../assets/img/carte/pizza/Reine.png";
import PizzaRaclette from "../assets/img/carte/pizza/Raclette.png";
import PizzaPimento from "../assets/img/carte/pizza/Pimento.png";
import PizzaPaysanne from "../assets/img/carte/pizza/Paysanne.png";
import PizzaOriental from "../assets/img/carte/pizza/Oriental.png";
import PizzaChèvre from "../assets/img/carte/pizza/Chèvre.png";
import PizzaMilano from "../assets/img/carte/pizza/milano.png";
import PizzaMexicaine from "../assets/img/carte/pizza/Mexicaine.png";
import PizzaKentuchy from "../assets/img/carte/pizza/Kentuchy.png";
import PizzaIndienne from "../assets/img/carte/pizza/Indienne.png";
import PizzaHawaienne from "../assets/img/carte/pizza/Hawaienne.png";
import PizzaForestiere from "../assets/img/carte/pizza/Forestière.png";
import PizzaCrispy from "../assets/img/carte/pizza/Crispy.png";
import PizzaCordonBleu from "../assets/img/carte/pizza/Cordon bleu.png";
import PizzaChorizo from "../assets/img/carte/pizza/Chorizo.png";
import PizzaMixte from "../assets/img/carte/pizza/Mixte.png";
import PizzaCanibal from "../assets/img/carte/pizza/new/Cannibal.png";
import PizzaChickenCheesy from "../assets/img/carte/pizza/new/Chicken Cheesy.png";
import PizzaCooperMontain from "../assets/img/carte/pizza/new/Cooper Montain.png";
import PizzaCrispyPepper from "../assets/img/carte/pizza/new/Crispy Pepper.png";
import PizzaMontana from "../assets/img/carte/pizza/new/Montana.png";
import PizzaSpanish from "../assets/img/carte/pizza/new/Spannish.png";
import PizzaSupremeRanch from "../assets/img/carte/pizza/new/Supreme Ranch.png";
import PizzaWhiteRost from "../assets/img/carte/pizza/new/White Roast.png";
import PizzaWyoming from "../assets/img/carte/pizza/new/Wyoming.png";
import PizzaMargarita from "../assets/img/carte/pizza/manq/Margarita.png";
import PizzaMerguez from "../assets/img/carte/pizza/manq/Merguez.png";
import PizzaArtichaud from "../assets/img/carte/pizza/manq/pizza artichaud.png";
import PizzaFruitsDeMer from "../assets/img/carte/pizza/manq/Pizza fruit de mer.png";
import PizzaJambon from "../assets/img/carte/pizza/manq/Pizza Jambon.png";
import PizzaKebab from "../assets/img/carte/pizza/manq/Pizza Kebab.png";
import PizzaNuggets from "../assets/img/carte/pizza/manq/Pizza nuggets.png";
import PizzaViandeHachee from "../assets/img/carte/pizza/manq/Pizza_ViandeHachée.png";

/*Gratin Images*/
import GratinCheesyBoston from "../assets/img/carte/gratin/cheeesy boston.png";
import GratinBrooklyn from "../assets/img/carte/gratin/Brooklyn.png";
import GratinAtlanta from "../assets/img/carte/gratin/atlanta.png";
import GratinDallas from "../assets/img/carte/gratin/dallas.png";
import GratinHarlem from "../assets/img/carte/gratin/Harlem.png";
import GratinMexico from "../assets/img/carte/gratin/mexicxo.png";
import GratinNebraska from "../assets/img/carte/gratin/Nebraska.png";
import GratinSoho from "../assets/img/carte/gratin/soho.png";

/*Salade Images*/
import SaladeBuratta from "../assets/img/carte/salade/saladeburatta.png";
import SaladeCesar from "../assets/img/carte/salade/saladecesar.png";
import SaladeDeThon from "../assets/img/carte/salade/saladedethon.png";
import SaladeDeSaumon from "../assets/img/carte/salade/saladesaumon.png";

/*Calzon Images*/
import CalzonBurger from "../assets/img/carte/calzone/Burger.png";
import CalzonFarmer from "../assets/img/carte/calzone/Farmer.png";
import CalzonNewYork from "../assets/img/carte/calzone/newyork.png";
import CalzonNorveg from "../assets/img/carte/calzone/Norvegienne.png";
import CalzonPecheur from "../assets/img/carte/calzone/pecheur.png";
import CalzonSicil from "../assets/img/carte/calzone/Sicilienne.png";

/*Signatures Images*/
import SignatureRoaster from "../assets/img/carte/signatures/Roaster.png";
import SignatureCheddarcrust from "../assets/img/carte/signatures/cheddarcrust.png";
import SignaturePizzaverde from "../assets/img/carte/signatures/pizzaverde.png";
import SignaturePepperlover from "../assets/img/carte/signatures/pepperlover.png";
import SignatureBuffalo from "../assets/img/carte/signatures/Buffalo.png";
import SignatureAlgérienne from "../assets/img/carte/signatures/Algérienne.png";
import SignatureDoritosSalsa from "../assets/img/carte/signatures/Pizza doritos salsa.png";
import SignatureDoritos from "../assets/img/carte/signatures/Pizza Doritos.png";
import SignatureDolceVita from "../assets/img/carte/signatures/Dolce Vita.png";
import SignatureGreenMeat from "../assets/img/carte/signatures/Pizza Green Meat.png";

/* Dessert Images */
import DessertMilkshake from "../assets/img/carte/dessert/Milkshake.png";
import DessertTiramisu from "../assets/img/carte/dessert/Tiramisu.png";
import DessertGlace from "../assets/img/carte/dessert/Sundea.png";
import DesserBubbleTea from "../assets/img/carte/dessert/BubbleTea.png";

/* TexMex Images */
import TexMexBouchees from "../assets/img/carte/texmex/BOUCHEES BOURSIN.png";
import TexMexCroqueMonsieur from "../assets/img/carte/texmex/Croque monsieur.png";
import TexMexJalapenos from "../assets/img/carte/texmex/Jalapenos.png";
import TexMexNuggets from "../assets/img/carte/texmex/Nuggets.png";
import TexMexSticksMozza from "../assets/img/carte/texmex/SticksMozza.png";
import TexMexTenders from "../assets/img/carte/texmex/Tenders.png";
import TexMexWings from "../assets/img/carte/texmex/Wings.png";
import TexMexBarquette from "../assets/img/carte/texmex/barquette de potatoes.png";

/* Junior Images */

import JuniorBerliner from "../assets/img/carte/junior/Berliner.png";
import JuniorBigBlue from "../assets/img/carte/junior/Big Blue.png";
import JuniorIndianRoast from "../assets/img/carte/junior/Indian Roast.png";
import JuniorLyonnaise from "../assets/img/carte/junior/Lyonnaise.png";
import JuniorMeatLover from "../assets/img/carte/junior/Meat Lover.png";
import JuniorOranaise from "../assets/img/carte/junior/Oranaise.png";
import JuniorPopSmoke from "../assets/img/carte/junior/Pop Smoke.png";
import JuniorSpicyOrientale from "../assets/img/carte/junior/Spicy Orientale.png";

export const products = [
  {
    id: 1,
    name: "Chèvre miel",
    img: PizzaChevreMiel,
    type: "Pizza",
  },
  {
    id: 2,
    name: "Cheese",
    img: PizzaCheese,
    type: "Pizza",
  },
  {
    id: 3,
    name: "Campione",
    img: PizzaCampione,
    type: "Pizza",
  },
  {
    id: 4,
    name: "Boursin",
    img: PizzaBoursin,
    type: "Pizza",
  },

  {
    id: 6,
    name: "4 Fromages",
    img: Pizza4Fromages,
    type: "Pizza",
  },
  {
    id: 7,
    name: "Vegetarienne",
    img: PizzaVegetarien,
    type: "Pizza",
  },
  {
    id: 8,
    name: "Thon",
    img: PizzaThon,
    type: "Pizza",
  },
  {
    id: 10,
    name: "Tartiflette",
    img: PizzaTartiflette,
    type: "Pizza",
  },
  {
    id: 11,
    name: "Saumon",
    img: PizzaSaumon,
    type: "Pizza",
  },
  {
    id: 13,
    name: "Reine",
    img: PizzaReine,
    type: "Pizza",
  },
  {
    id: 14,
    name: "Raclette",
    img: PizzaRaclette,
    type: "Pizza",
  },
  {
    id: 15,
    name: "Pimento",
    img: PizzaPimento,
    type: "Pizza",
  },
  {
    id: 16,
    name: "Paysanne",
    img: PizzaPaysanne,
    type: "Pizza",
  },

  {
    id: 17,
    name: "Orientale",
    img: PizzaOriental,
    type: "Pizza",
  },
  {
    id: 18,
    name: "Chèvre",
    img: PizzaChèvre,
    type: "Pizza",
  },
  {
    id: 19,
    name: "Milano",
    img: PizzaMilano,
    type: "Pizza",
  },
  {
    id: 20,
    name: "Mexicaine",
    img: PizzaMexicaine,
    type: "Pizza",
  },
  {
    id: 21,
    name: "Kentuchy",
    img: PizzaKentuchy,
    type: "Pizza",
  },
  {
    id: 22,
    name: "Indienne",
    img: PizzaIndienne,
    type: "Pizza",
  },
  {
    id: 23,
    name: "Hawaienne",
    img: PizzaHawaienne,
    type: "Pizza",
  },
  {
    id: 24,
    name: "Forestière",
    img: PizzaForestiere,
    type: "Pizza",
  },
  {
    id: 25,
    name: "Crispy",
    img: PizzaCrispy,
    type: "Pizza",
  },
  {
    id: 26,
    name: "Cordon Bleu",
    img: PizzaCordonBleu,
    type: "Pizza",
  },
  {
    id: 27,
    name: "Chorizo",
    img: PizzaChorizo,
    type: "Pizza",
  },
  {
    id: 29,
    name: "Mixte",
    img: PizzaMixte,
    type: "Pizza",
  },

  // Produse de tipul Gratin
  {
    id: 30,
    name: "Cheesy Boston",
    img: GratinCheesyBoston,
    type: "Gratin",
  },
  {
    id: 31,
    name: "Brooklyn",
    img: GratinBrooklyn,
    type: "Gratin",
  },
  {
    id: 32,
    name: "Atlanta",
    img: GratinAtlanta,
    type: "Gratin",
  },
  {
    id: 33,
    name: "Dallas",
    img: GratinDallas,
    type: "Gratin",
  },

  // Produse de tipul Salade
  {
    id: 34,
    name: "Buratta",
    img: SaladeBuratta,
    type: "Salade",
  },
  {
    id: 35,
    name: "Cesar",
    img: SaladeCesar,
    type: "Salade",
  },
  {
    id: 36,
    name: "De Thon",
    img: SaladeDeThon,
    type: "Salade",
  },
  {
    id: 37,
    name: "De Saumon",
    img: SaladeDeSaumon,
    type: "Salade",
  },

  // Produse de tipul Calzone
  {
    id: 38,
    name: "HAMBURGER",
    img: CalzonBurger,
    type: "Calzone",
  },
  {
    id: 39,
    name: "Farmer",
    img: CalzonFarmer,
    type: "Calzone",
  },
  {
    id: 40,
    name: "NEW-YORKAISE",
    img: CalzonNewYork,
    type: "Calzone",
  },
  {
    id: 41,
    name: "Norvegienne",
    img: CalzonNorveg,
    type: "Calzone",
  },
  {
    id: 42,
    name: "Pecheur",
    img: CalzonPecheur,
    type: "Calzone",
  },
  {
    id: 43,
    name: "Sicilienne",
    img: CalzonSicil,
    type: "Calzone",
  },

  // Produse de tipul Signature
  {
    id: 44,
    name: "Roaster",
    img: SignatureRoaster,
    type: "Signature",
  },
  {
    id: 45,
    name: "Cheddar Crust",
    img: SignatureCheddarcrust,
    type: "Signature",
  },
  // {
  //   id: 46,
  //   name: "Pizza Verde",
  //   img: SignaturePizzaverde,
  //   type: "Signature",
  // },
  {
    id: 47,
    name: "Pepper Lover",
    img: SignaturePepperlover,
    type: "Signature",
  },
  {
    id: 48,
    name: "Buffalo",
    img: SignatureBuffalo,
    type: "Signature",
  },
  {
    id: 49,
    name: "Algérienne",
    img: SignatureAlgérienne,
    type: "Signature",
  },
  {
    id: 50,
    name: "Harlem",
    img: GratinHarlem,
    type: "Gratin",
  },
  {
    id: 51,
    name: "Mexico",
    img: GratinMexico,
    type: "Gratin",
  },
  {
    id: 52,
    name: "Soho",
    img: GratinSoho,
    type: "Gratin",
  },
  {
    id: 53,
    name: "Nebraska",
    img: GratinNebraska,
    type: "Gratin",
  },
  {
    id: 54,
    name: "Tiramisu",
    img: DessertTiramisu,
    type: "Dessert",
  },
  {
    id: 55,
    name: "Milkshake",
    img: DessertMilkshake,
    type: "Dessert",
  },
  {
    id: 56,
    name: "Sundae",
    img: DessertGlace,
    type: "Dessert",
  },
  // {
  //   id: 57,
  //   name: "Bouchees boursin",
  //   img: TexMexBouchees,
  //   type: "TexMex",
  // },
  {
    id: 58,
    name: "Croque Monsieur",
    img: TexMexCroqueMonsieur,
    type: "TexMex",
  },
  {
    id: 59,
    name: "Jalapenos",
    img: TexMexJalapenos,
    type: "TexMex",
  },
  {
    id: 60,
    name: "Nuggets",
    img: TexMexNuggets,
    type: "TexMex",
  },
  {
    id: 61,
    name: "Sticks Mozza",
    img: TexMexSticksMozza,
    type: "TexMex",
  },
  {
    id: 62,
    name: "Tenders",
    img: TexMexTenders,
    type: "TexMex",
  },
  {
    id: 63,
    name: "Wings",
    img: TexMexWings,
    type: "TexMex",
  },
  {
    id: 64,
    name: "Barquette de potatoes",
    img: TexMexBarquette,
    type: "TexMex",
  },
  // {
  //   id: 65,
  //   name: "Pizza Doritos Salsa",
  //   img: SignatureDoritosSalsa,
  //   type: "Signature",
  // },
  // {
  //   id: 66,
  //   name: "Pizza Doritos",
  //   img: SignatureDoritos,
  //   type: "Signature",
  // },
  {
    id: 67,
    name: "Dolce Vita",
    img: SignatureDolceVita,
    type: "Signature",
  },
  // {
  //   id: 68,
  //   name: "Pizza Green Meat",
  //   img: SignatureGreenMeat,
  //   type: "Signature",
  // },
  {
    id: 69,
    name: "Pizza Canibal",
    img: PizzaCanibal,
    type: "Signature",
  },
  {
    id: 70,
    name: "Pizza Chicken Cheesy",
    img: PizzaChickenCheesy,
    type: "Signature",
  },
  {
    id: 71,
    name: "Pizza Cooper Montain",
    img: PizzaCooperMontain,
    type: "Signature",
  },
  {
    id: 72,
    name: "Pizza Crispy Pepper",
    img: PizzaCrispyPepper,
    type: "Signature",
  },
  {
    id: 73,
    name: "Pizza Montana",
    img: PizzaMontana,
    type: "Signature",
  },
  {
    id: 74,
    name: "Pizza Spanish",
    img: PizzaSpanish,
    type: "Signature",
  },
  {
    id: 75,
    name: "Pizza Supreme Ranch",
    img: PizzaSupremeRanch,
    type: "Signature",
  },
  {
    id: 76,
    name: "Pizza White Rost",
    img: PizzaWhiteRost,
    type: "Signature",
  },
  {
    id: 77,
    name: "Pizza Wyoming",
    img: PizzaWyoming,
    type: "Signature",
  },
  {
    id: 78,
    name: "Bubble Tea",
    img: DesserBubbleTea,
    type: "Dessert",
  },
  {
    id: 79,
    name: "Berliner",
    img: JuniorBerliner,
    type: "Junior",
  },
  {
    id: 80,
    name: "Big Blue",
    img: JuniorBigBlue,
    type: "Junior",
  },
  {
    id: 81,
    name: "Indian Roast",
    img: JuniorIndianRoast,
    type: "Junior",
  },
  {
    id: 82,
    name: "Lyonnaise",
    img: JuniorLyonnaise,
    type: "Junior",
  },
  {
    id: 83,
    name: "Meat Lover",
    img: JuniorMeatLover,
    type: "Junior",
  },
  {
    id: 84,
    name: "Oranaise",
    img: JuniorOranaise,
    type: "Junior",
  },
  {
    id: 85,
    name: "Pop Smoke",
    img: JuniorPopSmoke,
    type: "Junior",
  },
  {
    id: 86,
    name: "Spicy Orientale",
    img: JuniorSpicyOrientale,
    type: "Junior",
  },
  {
    id: 87,
    name: "Margarita",
    img: PizzaMargarita,
    type: "Pizza",
  },
  {
    id: 88,
    name: "Merguez",
    img: PizzaMerguez,
    type: "Pizza",
  },
  {
    id: 89,
    name: "Artichaud",
    img: PizzaArtichaud,
    type: "Pizza",
  },
  {
    id: 90,
    name: "Fruits de mer",
    img: PizzaFruitsDeMer,
    type: "Pizza",
  },
  {
    id: 91,
    name: "Jambon",
    img: PizzaJambon,
    type: "Pizza",
  },
  {
    id: 92,
    name: "Kebab",
    img: PizzaKebab,
    type: "Pizza",
  },
  {
    id: 93,
    name: "Nuggets",
    img: PizzaNuggets,
    type: "Pizza",
  },
  {
    id: 94,
    name: "Viande Hachee",
    img: PizzaViandeHachee,
    type: "Pizza",
  },
  // {
  //   id: 95,
  //   name: "",
  //   img: Pizza,
  //   type: "Pizza",
  // },
];
