import React, {useState} from 'react'
import '../assets/css/franchise.css'
import '../assets/css/accueil.css'
import 'bootstrap'
import axios from 'axios'
import {FaPaperPlane} from 'react-icons/fa6'
import { FaCheck } from "react-icons/fa";

const Franchise = () => {
  var formSubmitted = false;

  const [responseHTML, setResponseHTML] = useState("")

  function closeMessageForm(){
    const formFranchise = document.getElementById("franchise-form");
    const messageForm = document.getElementById("successForm");
    messageForm.classList.remove("animate__fadeIn");
    messageForm.classList.add("animate__fadeOut")
    setTimeout(() => {
      messageForm.style.display="none";
      formFranchise.classList.add("animate__fadeIn")
      formFranchise.style.display = "block";
    }, 1500);
  }

  function checkForm() {
    let nom = document.getElementById("firstname");
    let prenom = document.getElementById("lastname");
    let email = document.getElementById("email");
    let phone = document.getElementById("phone");
    let codePostal = document.getElementById("codePostal");
    let ville = document.getElementById("ville");
    let montant = document.getElementById("investment");
    let know = document.getElementById("know");
    let checkbox1 = document.getElementById("check1");
    let checkbox2 = document.getElementById("check2");

    if (
      nom.value === "" ||
      prenom.value === "" ||
      email.value === "" ||
      phone.value === "" ||
      codePostal.value === "" ||
      ville.value === "" ||
      know.value === "" ||
      montant.value === ""
    ) {
      alert("Veuillez remplir tous les champs du formulaire.");
      return false;
    } else if( checkbox1.checked === false || checkbox2.checked === false){
      alert("Veuillez accepte les conditions");
      return false;
    }
    else {
      if (!formSubmitted) {
        submitForm();
      }
      return true;
    }
  }
  function submitForm() {
    var formData = new FormData();
    formData.append('firstname', document.getElementById("firstname").value);
    formData.append('lastname', document.getElementById("lastname").value);
    formData.append('email', document.getElementById("email").value);
    formData.append('phone', document.getElementById("phone").value);
    formData.append('codePostal', document.getElementById("codePostal").value);
    formData.append('ville', document.getElementById("ville").value);
    formData.append('investment', document.getElementById("investment").value);
    formData.append('know', document.getElementById("know").value);
  
      axios.post('https://www.platanepizza.fr/sendMessage.php', formData)
        .then(function (response) {
          // Verificăm dacă răspunsul are statusul 200 și dacă există date valide
          if (response.status === 200) {
            // Afișează mesajul de succes
            console.log(response.data);
            setResponseHTML(response.data);
            alert("Le message a été envoyé avec succès ! Nous vous remercions de votre intérêt.");
            
            const formFranchise = document.getElementById("franchise-form");
            const messageForm = document.getElementById("successForm");
            formFranchise.classList.remove("animate__bounceIn");
            formFranchise.classList.add("animate__bounceOut");
            formFranchise.style.display="none";
            
            setTimeout(() => {
              messageForm.classList.add("animate__fadeIn");
              messageForm.style.display = "flex";
            }, 1500);
          } else {
            // Dacă serverul nu întoarce un răspuns valid, considerăm eroare
            console.error("Eroare la trimiterea formularului.");
            alert("Le message a été envoyé avec succès ! Nous vous remercions de votre intérêt.");
          }
        })
        .catch(function (error) {
          // Gestionați eroarea
          console.error("Eroare la trimiterea formularului:", error);
          alert("Eroare la trimiterea formularului. Vă rugăm să încercați din nou.");
        });
  }
  return (
  <>
      <main>
    <nav className="navbar navbar-expand-lg bg-black" id="navbarPLTN"></nav>
    <section id="headerTopForms">
      <div className="container-fluid">
        <div className="row d-flex flex-row justify-content-center align-items-center">
          <div className="col-12 col-md-10 col-lg-8">
            <h2 className="headerTopText">
              Vous souhaitez rejoindre <br />
              l'aventure ? <br />
              demandez un dossier de candidature
            </h2>
          </div>
        </div>
      </div>
    </section>
    <section
        id="franchiseContainerForm"
        className="formPlataneContainer scroll-reveal"
      >
        <div className="container">
          <div className="row d-flex justify-content-center align-items-center">
            <div className="col-12 col-md-10 col-lg-8 d-flex flex-column justify-content-center align-items-center">

            <form id="franchise-form" className="mt-5 mb-5 animate__animated animate__bounceIn" method="POST">
                <center>
                  <h2 className="tittlePage">Remplissez ce formulaire</h2>
                  <input
                    type="text"
                    className="mt-2 mb-2"
                    name="firstname"
                    id="firstname"
                    placeholder="Nom*"
                    required
                  />
                  <input
                    type="text"
                    name="lastname"
                    id="lastname"
                    className="mt-2 mb-2"
                    placeholder="Prenom*"
                    required
                  />
                  <br />
                  <input
                    type="email"
                    name="email"
                    id="email"
                    className="mt-2 mb-2"
                    placeholder="Email*"
                    required
                  />
                  <input
                    type="tel"
                    name="phone"
                    id="phone"
                    className="mt-2 mb-2"
                    placeholder="Telephone*"
                    required
                  />
                  <br />
                  <input
                    type="number"
                    name="codePostal"
                    id="codePostal"
                    className="mt-2 mb-2"
                    placeholder="Code postal*"
                    required
                  />
                  <input
                    type="Text"
                    name="ville"
                    id="ville"
                    className="mt-2 mb-2"
                    placeholder="Ville*"
                    required
                  />

                     <br />
                  <input
                    type="number"
                    name="investment"
                    id="investment"
                    className="mt-2 mb-2"
                    placeholder="Apport personnel*"
                    required
                  />
                  <br />
                  <div className='selectOptions'>
                  <label htmlFor="know">Comment avez-vous connu notre concept?&nbsp;</label>
                  <select name='know' id='know' className="mt-2 mb-2" required>
                    <option value=""> - </option>
                    <option value="TikTok">Tik Tok</option>
                    <option value="Instagram">Instagram</option>
                    <option value="Facebook">Facebook</option>
                    <option value="Snapchat">Snapchat</option>
                    <option value="UnAmi">Un Ami</option>
                    <option value="Autre">Autre</option>
                    </select>
                  </div>

                <div className='checkboxDiv'>
                  <div><input type="checkbox" name="checkCondition1" id="check1" required/>&nbsp;<label htmlFor="check1">J’accepte de recevoir des offres commerciales de la part de la société platane pizza</label></div>
                  <div><input type="checkbox" name="checkCondition2" id="check2" required />&nbsp;<label htmlFor="check2">J’accepte que les informations saisies soient exploitées dans le cadre de la candidature</label></div>
                </div>
                </center>

             
                

                <div className="row mt-2 mb-2">
                  <div className="col-12">
                  <div id="response-container" className='text-dark'>{responseHTML}</div>
                    <button type="button" className="nxtBTN" onClick={checkForm}>
                      Envoyer&nbsp;<FaPaperPlane />
                    </button>
                  </div>
                </div>
                
              </form>

            <div id='successForm'>
              <span><FaCheck /></span>
              <h4>
              Le message a été envoyé avec succès ! Nous vous remercions de votre intérêt.
              </h4>
              <button className='closeMessageForm' onClick={closeMessageForm}>Fermer</button>
            </div>

            </div>

          </div>
        </div>

      </section>
  </main>





  </>
  )
}

export default Franchise